@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cusIn input{
  /* border: 0; */
  background-color: transparent;

}
.cusIn div span {
  border: 0;
  background-color: transparent;

}

.cModal .ant-modal-content {
  padding: 0;
}

/* Light theme background gradient animation */
@keyframes lightGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.light-bg {
  background: linear-gradient(45deg, #f5f7fa, #c3cfe2, #e2f0cb, #feeafa);
  background-size: 200% 200%;
  animation: lightGradient 10s ease infinite;
}

/* Dark theme background gradient animation */
@keyframes darkGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dark-bg {
  background: linear-gradient(45deg, #1e1e1e, #333333, #4b4b4b, #000000);
  background-size: 200% 200%;
  animation: darkGradient 10s ease infinite;
}

/* Optional: Smooth transition between light and dark */
body {
  transition: background 0.5s ease;
}
