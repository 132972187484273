@keyframes pop {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .animate-pop {
    animation: pop 0.8s ease-out forwards;
  }
  
  .pop-effect {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pop-effect:hover {
    transform: scale(1.05);
  }
  